import { BiCheckShield } from '@react-icons/all-files/bi/BiCheckShield';
import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { useRouter } from 'next/router';

import { Badge } from 'components/design-system/Badge';
import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerForeground } from './common/DefaultBannerForeground';
import { DefaultBannerSubtitle } from './common/DefaultBannerSubtitle';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

export type ListingCountBannerProps = {
  listingCountDescription: React.ReactNode;
};

const desktopBannerImage = 'default-banner-desktop-background.jpg';
const mobileBannerImage = 'home-banner_image.png';

export const ListingCountBanner: React.FC<ListingCountBannerProps> = ({ listingCountDescription }) => {
  const { isTabletOrMobile, isMobile } = useViewport();
  const router = useRouter();
  const onClickShop = () => router.push(pageRoutes.explore());

  const contentContainerPositionTop = listingCountDescription === '' ? '-15%' : '-12.5%';

  const foregroundImage = `${process.env.NEXT_PUBLIC_CLOUDINARY_STATIC_PREFIX}/${
    isTabletOrMobile ? mobileBannerImage : desktopBannerImage
  }`;

  return (
    <DefaultBannerBackground color='#EEE9E3'>
      <DefaultBannerForeground
        priority
        image={{ src: foregroundImage }}
        orientation={isTabletOrMobile ? 'right -80px bottom -30px' : 'right'}
        style={{
          transform: isMobile ? 'scale(0.86)' : undefined,
          transformOrigin: 'bottom right'
        }}
      />
      <DefaultBannerContentContainer style={{ top: contentContainerPositionTop }}>
        <DefaultBannerSubtitle className='mb-3'>
          <Badge
            textClassname='fs-10px fs-md-12px riforma-medium'
            startIcon={{ icon: BiCheckShield, iconProps: { size: isTabletOrMobile ? 15 : 18 } }}
            bg='light'
          >
            Full Buyer Protection
          </Badge>
        </DefaultBannerSubtitle>
        <DefaultBannerTitle className='white-space-normal w-85 w-sm-55 text-primary'>
          Shop {listingCountDescription} luxury watches, fully{' '}
          <span className='bradford-regular-italic'>authenticated</span> in-house by Bezel&apos;s team of experts
        </DefaultBannerTitle>
        <DefaultBannerButton
          onClick={onClickShop}
          textClassname='text-primary'
          content='Shop now'
          endIcon={FaArrowRight}
        />
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
