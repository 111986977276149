import { IconBaseProps, IconType } from '@react-icons/all-files/lib';
import classNames from 'classnames';

import { Button, ButtonProps } from 'components/design-system/Button/Button';
import { useViewport } from 'context/viewport';

type DefaultCardButtonProps = {
  startIcon?: IconType;
  endIcon?: IconType;
  iconProps?: IconBaseProps;
  content?: React.ReactNode;
} & Omit<ButtonProps, 'content'>;

export const DefaultCardButton: React.FC<DefaultCardButtonProps> = ({
  className,
  style,
  startIcon,
  endIcon,
  iconProps,
  children,
  content,
  textClassname,
  ...rest
}) => {
  const StartIcon = startIcon ? startIcon : undefined;
  const EndIcon = endIcon ? endIcon : undefined;
  const { isMobile } = useViewport();

  return (
    <Button
      className={classNames(
        'd-flex align-items-center fs-14px fs-md-16px px-3 py-3 rounded-1 shadow-on-hover riforma-regular',
        className
      )}
      textClassname={classNames(textClassname, 'w-inherit mx-1 d-flex justify-content-between align-items-center mx-1')}
      style={{ width: isMobile ? '100%' : 215, border: 'none', ...style }}
      {...rest}
    >
      {StartIcon && <StartIcon size={isMobile ? 14 : 16} {...iconProps} />}
      {content}
      {children}
      {EndIcon && <EndIcon size={isMobile ? 14 : 16} {...iconProps} />}
    </Button>
  );
};
