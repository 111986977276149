import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

type DefaultCardContentContainerProps = {
  overrideDefaultClassname?: string;
} & PropsWithChildren &
  StyledComponent;

export const DefaultCardContentContainer: React.FC<DefaultCardContentContainerProps> = ({
  className,
  style,
  children,
  overrideDefaultClassname
}) => {
  const { isMobile } = useViewport();

  return (
    <div
      className={classNames(
        overrideDefaultClassname ||
          'w-100 w-sm-50 ps-4 pe-4 ps-md-5 ps-lg-6 pe-md-0 py-4 pt-0 pt-md-5 pb-md-5 d-flex flex-column justify-content-between',
        className
      )}
      style={{ minHeight: isMobile ? 'auto' : '100%', zIndex: 2, ...style }}
    >
      {children}
    </div>
  );
};
