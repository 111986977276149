import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { FC, memo, useCallback, useMemo, useState } from 'react';
import { GrNext } from '@react-icons/all-files/gr/GrNext';
import { GrPrevious } from '@react-icons/all-files/gr/GrPrevious';
import classNames from 'classnames';
import SwiperCore, { Autoplay, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { useViewport } from 'context/viewport';
import { useEquinox } from 'hooks/useEquinox';
import styles from 'styles/Banner.module.scss';
import { Feature, ListingStat } from 'types';

import { BannerCarouselContentLoader } from './banners/BannerCarouselContentLoader';
import { getListingCountDescription } from './banners/helpers';
import { AuctionsCarouselBanner } from './v2/banners/AuctionsCarouselBanner';
import { ConciergeBanner } from './v2/banners/ConciergeBanner';
import { EquinoxBanner } from './v2/banners/EquinoxBanner';
import { FeatureCarousel } from './v2/banners/FeatureCarousel';
import { InvestorsBanner } from './v2/banners/InvestorsBanner';
import { ListingCountBanner } from './v2/banners/ListingCountBanner';
import { FeaturedPress } from './v2/promotional/FeaturedPress';

const CAROUSEL_INTERVAL = 10000; // 10sec

type BannerCarouselProps = {
  bannerItems: Feature[];
  listingStatsCount: number;
  auctionsStats: ListingStat;
};

export const BannerCarousel: FC<BannerCarouselProps> = memo(({ bannerItems, listingStatsCount, auctionsStats }) => {
  const { isTabletOrMobile, isLoaded: isViewportLoaded } = useViewport();
  const { showEquinoxBanner, isLoading: equinoxIsLoading } = useEquinox();
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const swiperModules = useMemo(() => [Autoplay], []);

  const listingCountDescription = listingStatsCount ? getListingCountDescription(listingStatsCount) : '';

  const isLoading = equinoxIsLoading || !isViewportLoaded;

  SwiperCore.use([Lazy]);

  const incrementIdx = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  const decrementIdx = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  if (isLoading) {
    return (
      <div className={styles['carousel-placeholder']}>
        <BannerCarouselContentLoader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.carousel}>
        {!isTabletOrMobile && (
          <>
            <div
              className={classNames(
                styles.navigationContainer,
                'position-absolute start-0 h-100 d-flex align-items-center justify-content-center'
              )}
              style={{ zIndex: 2, width: '15%' }}
              role='button'
              onClick={decrementIdx}
            >
              <div
                className={classNames(
                  styles.navigation,
                  'd-flex justify-content-center bg-white align-items-center prev-button'
                )}
              >
                <GrPrevious />
              </div>
            </div>
            <div
              className={classNames(
                styles.navigationContainer,
                'position-absolute end-0 h-100 d-flex align-items-center justify-content-center'
              )}
              style={{ zIndex: 2, width: '15%' }}
              role='button'
              onClick={incrementIdx}
            >
              <div
                className={classNames(
                  styles.navigation,
                  'd-flex justify-content-center bg-white align-items-center next-button'
                )}
              >
                <GrNext />
              </div>
            </div>
          </>
        )}
        <Swiper
          loop
          lazy
          autoplay={{
            delay: CAROUSEL_INTERVAL,
            disableOnInteraction: false
          }}
          allowTouchMove={isTabletOrMobile}
          speed={750}
          slidesPerView={1}
          spaceBetween={0}
          onSwiper={setSwiper}
          modules={swiperModules}
        >
          <SwiperSlide>
            <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
              <ListingCountBanner listingCountDescription={listingCountDescription} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
              <ConciergeBanner />
            </div>
          </SwiperSlide>
          {auctionsStats && auctionsStats.count > 0 && (
            <SwiperSlide>
              <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
                <AuctionsCarouselBanner auctionsCount={auctionsStats.count} />
              </div>
            </SwiperSlide>
          )}
          {showEquinoxBanner && (
            <SwiperSlide>
              <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
                <EquinoxBanner />
              </div>
            </SwiperSlide>
          )}
          {bannerItems.slice(0, 2).map((bannerItem, id) => (
            <SwiperSlide key={`${bannerItem.id}-${id}`}>
              <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
                <FeatureCarousel feature={bannerItem as Feature} />
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
              <InvestorsBanner />
            </div>
          </SwiperSlide>
          {bannerItems.slice(2).map((bannerItem, id) => (
            <SwiperSlide key={`${bannerItem.id}-${id}`}>
              <div className={classNames('d-block w-100', styles['carousel-item-container'])}>
                <FeatureCarousel feature={bannerItem as Feature} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <FeaturedPress />
    </>
  );
});
