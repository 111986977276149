import { FC } from 'react';
import classNames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { AuctionListingItem } from 'components/auctions/Listings/AuctionListingItem';
import { Carousel } from 'components/common/Carousel/Carousel';
import { ContentLoader } from 'components/design-system/ContentLoader';
import { useViewport } from 'context/viewport';
import { Listing } from 'types';

type AuctionsCarouselProps = {
  items: Listing[];
  title: React.ReactNode;
  loading?: boolean;
  className?: string;
};

const desktopSlidesPerView = 3;
const tabletOrMobileSlidesPerView = 2;

export const AuctionsCarousel: FC<AuctionsCarouselProps> = ({ items, title, className, loading }) => {
  const { isTabletOrMobile } = useViewport();

  const slidesPerView = isTabletOrMobile ? tabletOrMobileSlidesPerView : desktopSlidesPerView;

  return (
    <div className={classNames('w-100 py-3 mb-4 bg-darkTheme', className)}>
      <div className='container main-content py-3 px-3 px-lg-0 riforma-medium position-relative'>
        <div className='mb-1 text-light fs-16px fs-sm-18px mb-2'>{title}</div>
        <Carousel
          isArrowsOutsideOfSlide
          isDarkFromProps
          swiperProps={{
            loop: true,
            lazy: true,
            spaceBetween: 10,
            loopedSlides: slidesPerView,
            initialSlide: slidesPerView
          }}
          slidesPerView={slidesPerView}
        >
          {items?.map((item, idx) => (
            <SwiperSlide key={`slide-${idx}-${item.id}`}>
              {loading ? (
                <ContentLoader borderRadius={20} height={isTabletOrMobile ? '310px' : '420px'} />
              ) : (
                <AuctionListingItem fullWidth listing={item} />
              )}
            </SwiperSlide>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
