import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

import { AdvantageItem } from '../common/types';

type DefaultAuthAdvantageItemProps = AdvantageItem & StyledComponent;

export const DefaultAuthAdvantageItem: React.FC<DefaultAuthAdvantageItemProps> = ({
  title,
  description,
  icon,
  className,
  style,
  textContainer,
  iconToContentSpacer,
  titleToDescriptionSpacer
}) => {
  const { isMobile } = useViewport();
  const IconComponent = icon.IconComponent;

  return (
    <div
      className={classNames(
        'ps-4 ps-md-3 d-flex flex-column flex-md-row justify-content-between gap-md-2',
        isMobile && 'py-4 rounded-3 pe-4',
        className
      )}
      style={{ borderLeft: isMobile ? '1px solid rgba(26, 58, 50, 0.2)' : undefined, ...style }}
    >
      {!isMobile && (
        <div
          className={classNames(
            'd-flex align-items-center justify-content-center rounded-3 fs-16px riforma-regular align-self-start',
            icon.className
          )}
          style={{ width: 'fit-content', ...icon.style }}
        >
          {IconComponent && <IconComponent className='m-2 m-md-3 ' size={isMobile ? 16 : 23} {...icon.iconProps} />}
          {icon.content && <>{icon.content}</>}
        </div>
      )}
      <div className={classNames('pb-0 pb-md-4', iconToContentSpacer?.className)} style={iconToContentSpacer?.style} />
      <div className={textContainer?.className} style={textContainer?.style}>
        <div className='d-flex flex-row align-items-center gap-3'>
          {isMobile && (
            <div
              className={classNames(
                'd-flex align-items-center justify-content-center rounded-3 fs-16px riforma-regular',
                icon.className
              )}
              style={{ width: 'fit-content', ...icon.style }}
            >
              {IconComponent && <IconComponent className='m-2 m-md-3 ' size={isMobile ? 16 : 23} {...icon.iconProps} />}
              {icon.content && <>{icon.content}</>}
            </div>
          )}
          <div className={classNames('fs-16px', title.className)} style={title.style}>
            {title.content}
          </div>
        </div>
        <div
          className={classNames('pb-2 pb-md-3', titleToDescriptionSpacer?.className)}
          style={titleToDescriptionSpacer?.style}
        />
        <div
          className={classNames('fs-14px opacity-60 line-height-24px', description.className)}
          style={description.style}
        >
          {description.content}
        </div>
      </div>
    </div>
  );
};
