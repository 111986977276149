import classNames from 'classnames';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import { Carousel } from 'components/common/Carousel';
import { ContentLoader } from 'components/design-system/ContentLoader';
import { useViewport } from 'context/viewport';
import { Article, StyledComponent } from 'types';

import { ArticleCard } from './ArticleCard';

type LoadingItemType = { id: number; loading: true };
type ArticleCarouselProps = {
  title: string;
  items: (Article | LoadingItemType)[];
  loading?: boolean;
} & StyledComponent;

const preRenderedVirtualSlidesAmount = 3;

const swiperProps: SwiperProps = {
  className: classNames('px-4 pt-3 w-100'),
  virtual: {
    cache: true,
    addSlidesAfter: preRenderedVirtualSlidesAmount,
    addSlidesBefore: preRenderedVirtualSlidesAmount
  }
};

const placeholders = Array.from(new Array(5)).map((_, i) => ({ id: i }));
const HEIGHT_MOBILE = '225px';
const HEIGHT_DESKTOP = '275px';

export const ArticleCarousel: React.FC<ArticleCarouselProps> = ({ title, className, items, loading }) => {
  const { isMobileSmall } = useViewport();
  const articles = items || placeholders;

  return (
    <Carousel
      showArrowGradient
      showArrowBackground
      title={title}
      className={className}
      swiperProps={swiperProps}
      slidesPerView={isMobileSmall ? 1.5 : 2.3}
    >
      {articles.map((item, idx) => (
        <SwiperSlide
          key={`article-carousel-item-${idx}-${item.id}`}
          className={classNames('pb-4', isMobileSmall ? 'w-65' : 'w-40')}
          virtualIndex={idx}
        >
          {loading ? (
            <ContentLoader borderRadius={20} height={isMobileSmall ? HEIGHT_MOBILE : HEIGHT_DESKTOP} />
          ) : (
            <div style={{ height: isMobileSmall ? HEIGHT_MOBILE : HEIGHT_DESKTOP }}>
              <ArticleCard article={item as Article} />
            </div>
          )}
        </SwiperSlide>
      ))}
    </Carousel>
  );
};
