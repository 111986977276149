import { ImageProps } from 'next/image';

export type FeaturedPressItem = {
  logo: Omit<ImageProps, 'fill'>;
  href: string;
};

export const featuredPressItems: FeaturedPressItem[] = [
  {
    logo: {
      src: 'bloomberg-light.png',
      width: 90,
      height: 18,
      alt: 'Bloomberg Featured Press Light Logo'
    },
    href: 'https://www.bloomberg.com/news/articles/2023-01-10/rolex-reseller-bezel-wins-financial-backing-from-ovitz-hart'
  },
  {
    logo: {
      src: 'barrons-light.png',
      width: 85,
      height: 18,
      alt: 'Barrons Featured Press Light Logo'
    },
    href: 'https://www.barrons.com/articles/generation-z-finding-time-for-second-hand-luxury-watches-01674617109'
  },
  {
    logo: {
      src: 'wsj-full-light.png',
      width: 142,
      height: 15,
      alt: "Wall Street's Journal Feature Press Light Logo"
    },
    href: 'https://www.wsj.com/articles/womens-analog-watches-spring-fashion-2023-fca68be3'
  },
  {
    logo: {
      src: 'fader-light.png',
      width: 67,
      height: 20,
      alt: 'Fader Featured Press Light Logo'
    },
    href: 'https://www.thefader.com/2023/03/09/a-brief-history-of-luxury-watches-in-hip-hop-videos'
  },
  {
    logo: {
      src: 'watchoninsta-light.png',
      width: 92,
      height: 16,
      alt: 'Watchonista Featured Press Light Logo'
    },
    href: 'https://www.watchonista.com/articles/watch-industry/presenting-watchonistas-30-under-30ish-list'
  },
  {
    logo: {
      src: 'airmail-light.png',
      width: 60,
      height: 36,
      alt: 'Airmail Featured Press Light Logo'
    },
    href: 'https://airmail.news/issues/2022-6-25/bezel'
  }
];
