import { CSSProperties } from 'react';
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight';
import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { Button } from 'components/design-system/Button';
import { StyledComponent } from 'types';

type AuthAdvantageItemsButtonProps = {
  content: string;
} & StyledComponent;

const defaultButtonStyle: CSSProperties = {
  backgroundColor: 'rgba(255, 252, 249, 0.07)'
};

export const AuthAdvantageItemsButton: React.FC<AuthAdvantageItemsButtonProps> = ({ className, style, content }) => (
  <Link href='https://www.getbezel.com/authentication' target='_blank'>
    <Button
      variant='custom'
      className={classNames(
        'w-100 py-3 px-4 my-2 text-white position-relative d-flex flex-row align-items-center justify-content-center rounded-2 shadow-on-hover',
        className
      )}
      style={{ ...defaultButtonStyle, ...style }}
    >
      <span className='fs-16px riforma-regular justify-self-center'>{content}</span>
      <BiChevronRight style={{ right: 18 }} className='position-absolute' size={25} />
    </Button>
  </Link>
);
