import { Badge as BootstrapBadge, BadgeProps as BootstrapBadgeProps } from 'react-bootstrap';
import { IconBaseProps, IconType } from '@react-icons/all-files/lib';
import classNames from 'classnames';

import { StyledComponent } from 'types';

import styles from './Badge.module.scss';

type BadgeIcon = {
  icon: IconType;
  iconProps?: IconBaseProps;
} & StyledComponent;

type BadgeProps = {
  startIcon?: BadgeIcon;
  endIcon?: BadgeIcon;
  textClassname?: string;
} & BootstrapBadgeProps &
  StyledComponent;

export const Badge: React.FC<BadgeProps> = ({
  className,
  style,
  children,
  startIcon,
  endIcon,
  bg,
  textClassname,
  ...rest
}) => {
  const StartIcon = startIcon ? startIcon?.icon : undefined;
  const EndIcon = endIcon ? endIcon?.icon : undefined;

  return (
    <BootstrapBadge
      className={classNames(
        'd-flex flex-row align-items-center riforma-regular fs-10px fs-md-14px rounded-2',
        styles['container'],
        bg && styles[`variant-${bg}`],
        className
      )}
      style={style}
      bg={bg}
      {...rest}
    >
      <div className='d-flex flex-row align-items-center'>
        {StartIcon && (
          <StartIcon
            className={classNames('me-1', startIcon?.className)}
            style={startIcon?.style}
            {...startIcon?.iconProps}
          />
        )}
        <span className={classNames('my-auto', textClassname)}>{children}</span>
        {EndIcon && (
          <EndIcon
            className={classNames('ms-1', endIcon?.className)}
            style={startIcon?.style}
            {...endIcon?.iconProps}
          />
        )}
      </div>
    </BootstrapBadge>
  );
};
