import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import styles from 'styles/Banner.module.scss';
import { StyledComponent } from 'types';

type DefaultBannerContentContainerProps = {
  container?: StyledComponent;
  contentContainer?: StyledComponent;
} & StyledComponent &
  PropsWithChildren;

export const DefaultBannerContentContainer: React.FC<DefaultBannerContentContainerProps> = ({
  className,
  style,
  container,
  contentContainer,
  children
}) => {
  const { isMobile } = useViewport();

  return (
    <div className={classNames('position-absolute top-0 w-100 h-100', container?.className)} style={container?.style}>
      <div
        className={classNames(
          'd-flex flex-column h-100 main-content p-0 justify-content-center',
          contentContainer?.className
        )}
        style={contentContainer?.style}
      >
        <div
          className={classNames(
            'riforma-regular white-space-break ps-4 my-md-auto',
            styles['banner-item-container'],
            isMobile && 'position-relative',
            className
          )}
          style={{
            top: isMobile ? '-8%' : 'auto',
            ...style
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
