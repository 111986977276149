import { PropsWithChildren } from 'react';
import { ImageLoaderProps } from 'next/image';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

type ImageData = ImageLoaderProps & { overrideTransformations?: string[] } & StyledComponent;

type DefaultCardGraphicProps = {
  content?: React.ReactNode;
  image?: ImageData;
} & StyledComponent &
  PropsWithChildren;

export const DefaultCardGraphic: React.FC<DefaultCardGraphicProps> = ({ className, content, children, image }) => {
  const { isMobile } = useViewport();

  const getCommonProps = (imageData: ImageData) =>
    ({
      src: imageData.src,
      alt: 'Card graphic',
      style: {
        objectFit: 'contain',
        objectPosition: isMobile ? 'top' : 'right -30px bottom 0',
        ...imageData.style
      }
    }) as const;

  return (
    <div
      className={classNames(
        'w-100',
        image ? 'w-sm-100 pointer-events-none' : 'w-sm-45',
        image && !isMobile && 'position-absolute h-100',
        className
      )}
    >
      {content}
      {children}
      {image &&
        (isMobile ? (
          <CloudinaryImage {...getCommonProps(image)} responsive sizes='100vw' />
        ) : (
          <CloudinaryImage {...getCommonProps(image)} fill sizes='50vw' />
        ))}
    </div>
  );
};
