import classNames from 'classnames';

import { StyledComponent } from 'types';

import styles from './DefaultCardItem.module.scss';

export const DefaultCardHeadingSubtitleTestIds = {
  container: 'default-card-heading-subtitle-container'
};

export type DefaultCardHeadingSubtitleProps = {
  content?: React.ReactNode;
} & StyledComponent;

export const DefaultCardHeadingSubtitle: React.FC<DefaultCardHeadingSubtitleProps> = ({
  content,
  className,
  style
}) => (
  <div
    data-testid={DefaultCardHeadingSubtitleTestIds.container}
    className={classNames(
      'mx-4 mx-md-3 mx-xl-auto pb-3 text-primary fs-18px fs-md-20px',
      styles['default-card-item-heading-subtitle'],
      className
    )}
    style={style}
  >
    {content}
  </div>
);
