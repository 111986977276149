import { FaArrowRight } from '@react-icons/all-files/fa6/FaArrowRight';
import { useRouter } from 'next/router';

import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';
import { Feature } from 'types';
import { getCustomMarkupFormattedText } from 'utils/text-helpers/getCustomMarkupFormattedText';

import { DefaultBannerBackground } from './common/DefaultBannerBackground';
import { DefaultBannerButton } from './common/DefaultBannerButton';
import { DefaultBannerContentContainer } from './common/DefaultBannerContentContainer';
import { DefaultBannerForeground } from './common/DefaultBannerForeground';
import { DefaultBannerSubtitle } from './common/DefaultBannerSubtitle';
import { DefaultBannerTitle } from './common/DefaultBannerTitle';

type FeatureCarouselProps = {
  feature: Feature;
};

export const FeatureCarousel: React.FC<FeatureCarouselProps> = ({ feature }) => {
  const router = useRouter();
  const { isMobile } = useViewport();

  const onClickShop = () => router.push(pageRoutes.fullCollectionUrl(feature.object.name, feature.object.id));
  const desktopForeground = feature.object.foregroundImages['BANNER_CAROUSEL']?.optimizedUrl || '';
  const desktopBackground = feature.object.backgroundImages['BANNER_CAROUSEL']?.optimizedUrl || '';
  const mobileForeground = feature.object.foregroundImages['MOBILE_BANNER_CAROUSEL']?.optimizedUrl || '';
  const mobileBackground = feature.object.backgroundImages['MOBILE_BANNER_CAROUSEL']?.optimizedUrl || desktopBackground;

  return (
    <DefaultBannerBackground
      image={{
        src: isMobile ? mobileBackground : desktopBackground,
        alt: `${feature.object.name} Banner Background Image`
      }}
    >
      <DefaultBannerForeground
        image={{
          src: isMobile ? mobileForeground : desktopForeground
        }}
        orientation={isMobile ? 'right -120px bottom 0px' : 'right 0px bottom 0px'}
      />
      <DefaultBannerContentContainer className='text-light' style={{ top: '-15%' }}>
        <DefaultBannerSubtitle className='opacity-60' content={feature.label?.toUpperCase() || 'COLLECTION'} />
        <DefaultBannerTitle
          content={getCustomMarkupFormattedText(feature.object.formattedTitle)}
          className='w-80 w-sm-55'
        />
        <DefaultBannerButton
          content='Shop now'
          endIcon={FaArrowRight}
          onClick={onClickShop}
          textClassname='text-light'
        />
      </DefaultBannerContentContainer>
    </DefaultBannerBackground>
  );
};
