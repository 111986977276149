import { IconBaseProps, IconType } from '@react-icons/all-files/lib';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

export const DefaultCardSubtitleTestIds = {
  container: 'default-card-subtitle-container',
  startIcon: 'default-card-subtitle-start-icon',
  endIcon: 'default-card-subtitle-end-icon'
};

export type DefaultCardSubtitleProps = {
  content?: string;
  startIcon?: IconType;
  endIcon?: IconType;
  iconProps?: IconBaseProps;
} & StyledComponent;

export const DefaultCardSubtitle: React.FC<DefaultCardSubtitleProps> = ({
  className,
  style,
  startIcon,
  endIcon,
  iconProps,
  content
}) => {
  const StartIcon = startIcon ? startIcon : undefined;
  const EndIcon = endIcon ? endIcon : undefined;
  const { isMobile } = useViewport();

  return (
    <div
      data-testid={DefaultCardSubtitleTestIds.container}
      className={classNames('fs-16px fs-sm-18px d-flex align-items-center riforma-medium', className)}
      style={style}
    >
      {StartIcon && (
        <StartIcon
          data-testid={DefaultCardSubtitleTestIds.startIcon}
          className='me-2'
          size={isMobile ? 14 : 16}
          {...iconProps}
        />
      )}
      {content}
      {EndIcon && (
        <EndIcon
          data-testid={DefaultCardSubtitleTestIds.endIcon}
          className='ms-2'
          size={isMobile ? 14 : 16}
          {...iconProps}
        />
      )}
    </div>
  );
};
